import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEditorPath } from "hooks/store/useGetEditorPath";
import {
  EditorRouteBottomPanelParams,
  WORKFLOW_EDITOR_URL,
  SCHEDULED_JOB_EDITOR_URL,
  EditorRoute,
} from "legacy/constants/routes";
import { ApiTriggerType } from "store/slices/apis/types";
import { getTriggerTypeFromApi } from "store/slices/apisV2";
import { BackendTypes } from "store/slices/apisV2";

interface UseNavigateToBlockProps {
  api: BackendTypes.Api;
}

export const useNavigateToBlock = ({ api }: UseNavigateToBlockProps) => {
  const params = useParams<EditorRouteBottomPanelParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const triggerType = getTriggerTypeFromApi(api);
  const getEditorPath = useGetEditorPath();

  const navigateToBlockForApi = useCallback(
    (blockName: string) => {
      let pathname: string | undefined;
      if (triggerType === ApiTriggerType.UI && params.applicationId) {
        pathname = getEditorPath(EditorRoute.EditApiAction, {
          applicationId: params.applicationId,
          apiId: api.metadata.id,
          actionId: blockName,
        });
      } else if (triggerType === ApiTriggerType.WORKFLOW) {
        pathname = WORKFLOW_EDITOR_URL(params.apiId, blockName);
      } else if (triggerType === ApiTriggerType.SCHEDULE) {
        pathname = SCHEDULED_JOB_EDITOR_URL(params.apiId, blockName);
      }
      if (pathname) {
        navigate({
          pathname,
          search: location.search,
        });
      } else {
        console.error("Unable to navigate to block");
      }
    },
    [
      getEditorPath,
      api.metadata.id,
      navigate,
      location.search,
      params.apiId,
      params.applicationId,
      triggerType,
    ],
  );

  return { navigateToBlockForApi };
};
