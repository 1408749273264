import { takeEvery } from "redux-saga/effects";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import replayManager from "legacy/workers/replay/ApiDSLReplayManager";
import { resolveById } from "store/utils/resolveIdSingleton";
import { callSagas } from "store/utils/saga";
import { fastClone } from "utils/clone";
import logger from "utils/logger";
import { updateV2ApiSaga } from "./updateV2Api";
import type * as BackendTypes from "../backend-types";

interface ApiUndoRedoPayload {
  operation: "UNDO" | "REDO";
  apiId: string;
  times?: number;
  callbackId?: string;

  // : (blockName: string) => void; // todo dont pass callback once https://github.com/superblocksteam/superblocks/pull/3930 is merged
}

function* undoRedoSaga(action: ReduxAction<ApiUndoRedoPayload>) {
  const { apiId, operation, times } = action.payload;
  try {
    const response = replayManager.replay(apiId, operation, times);
    if (!response) return;

    const { logs, replayDSL, replay } = response;
    logs && logs.forEach((evalLog: any) => logger.debug(evalLog));
    yield callSagas([
      updateV2ApiSaga.apply({
        apiPb: fastClone(replayDSL) as BackendTypes.Api,
      }),
    ]);
    if (replay.updatedBlockName) {
      if (action.payload.callbackId) {
        resolveById(action.payload.callbackId, replay.updatedBlockName);
      }
    }
  } catch (e) {
    logger.error(e);
  }
}

export function* apiUndoRedoListenerSaga() {
  yield takeEvery(ReduxActionTypes.API_UNDO_REDO_OPERATION, undoRedoSaga);
}
