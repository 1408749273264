import { put, select, call } from "redux-saga/effects";
import { undoAction } from "legacy/actions/pageActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { worker } from "legacy/sagas/evaluationLoader";
import { EVAL_WORKER_ACTIONS } from "legacy/utils/DynamicBindingUtils";
import apiDSLReplayManager from "legacy/workers/replay/ApiDSLReplayManager";
import { deleteV2ApiSaga } from "../../apisV2/sagas/deleteV2Api";
import { selectAiState } from "../selectors";
import { resetAiState } from "../slice";

export function* clearAiChangesSaga(action: {
  payload: { isAccept?: boolean; shouldClose: boolean; isRedo?: boolean };
}) {
  if (!action.payload.isAccept) {
    // Undo back to the previous state before this clark request
    const {
      widgetsUndoStackLengthAtRequestStart,
      apisUndoStackLengthsAtRequestStart,
      apiChanges,
      apisAtRequestStart,
    } = yield select(selectAiState);

    const currentUndoStackLength: number = yield call(
      worker.request,
      EVAL_WORKER_ACTIONS.GET_UNDO_STACK_LENGTH,
      {},
    );

    if (currentUndoStackLength > widgetsUndoStackLengthAtRequestStart) {
      const undosNeeded =
        currentUndoStackLength - widgetsUndoStackLengthAtRequestStart;
      yield put(undoAction({ times: undosNeeded }));
    }

    // Delete any net new apis created, as we don't have undo for these
    for (const apiId of Object.keys(apiChanges ?? {})) {
      if (!apisAtRequestStart[apiId]) {
        const deleteApiAction = {
          type: deleteV2ApiSaga.start.type,
          payload: {
            id: apiId,
            branch: undefined,
            localOnly: true,
          },
        };
        yield put(deleteApiAction);
      }
    }

    // undo the api changes
    const apiIds = Object.keys(apisUndoStackLengthsAtRequestStart ?? {});
    for (const apiId of apiIds) {
      const apiUndoStackLengthAtRequestStart =
        apisUndoStackLengthsAtRequestStart[apiId];
      const currentApiUndoStackLength =
        apiDSLReplayManager.replayManagersByApiId
          .get(apiId)
          ?.getUndoStackLength();

      if (
        currentApiUndoStackLength &&
        currentApiUndoStackLength > apiUndoStackLengthAtRequestStart
      ) {
        const undosNeeded =
          currentApiUndoStackLength - apiUndoStackLengthAtRequestStart;
        yield put({
          type: ReduxActionTypes.API_UNDO_REDO_OPERATION,
          payload: {
            apiId,
            operation: "UNDO",
            times: undosNeeded,
          },
        });
      }
    }
  }

  // now clear out ai state
  yield put(
    resetAiState({
      shouldClose: action.payload.shouldClose,
      isRedo: action.payload.isRedo ?? false,
    }),
  );
}
