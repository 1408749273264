import { createAction } from "@reduxjs/toolkit";
import {
  CachedData6,
  Dimension,
  EventDefinitionLoose,
  Padding,
  PageDSL8,
  RouteDef,
} from "@superblocksteam/shared";
import {
  ApplicationPayload,
  ReduxAction,
  ReduxActionTypes,
  ReplayReduxActionTypes,
  UpdateWidgetPositionsPayload,
} from "legacy/constants/ReduxActionConstants";
import {
  type WidgetType,
  type WidgetTypes,
  type WidgetHeightConstraintType,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { type StackAdjustmentsInfo } from "legacy/widgets/StackLayout/types";
import { WidgetOperation } from "legacy/widgets/WidgetOperations";
import { AppStateVar } from "store/slices/application/stateVars/StateConstants";
import { AppTimer } from "store/slices/application/timers/TimerConstants";
import { APP_MODE } from "../reducers/types";
import type { UrlDataState } from "legacy/reducers/entityReducers/appReducer";
import type { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import type { WidgetMap } from "legacy/widgets";

export const fetchPageSuccess = createAction("FETCH_PAGE_SUCCESS");

type FetchPublishedPageSuccessPayload = {
  pageId: string;
  dsl: PageDSL8;
  pageWidgetId: string;
};

export const fetchPublishedPageSuccess = createAction(
  "FETCH_PUBLISHED_PAGE_SUCCESS",
  (payload: FetchPublishedPageSuccessPayload) => ({ payload }),
);

export const pageLoadSuccess = createAction("PAGE_LOAD_SUCCESS");

export const switchCurrentPage = createAction(
  "SWITCH_CURRENT_PAGE_ID",
  (id: string) => ({
    payload: { id },
  }),
);

export const createPageInit = createAction(
  "CREATE_PAGE_INIT",
  (
    pageName: string,
    routePath: string,
    routeTestParams: Record<string, string>,
    switchToPage = true,
  ) => ({
    payload: { name: pageName, routePath, routeTestParams, switchToPage },
  }),
);

export const createPageSuccess = createAction(
  "CREATE_PAGE_SUCCESS",
  (payload: {
    pageId: string;
    pageName: string;
    configuration: ApplicationPayload["configuration"];
  }) => ({
    payload,
  }),
);

export const duplicatePageInit = createAction(
  "DUPLICATE_PAGE_INIT",
  (payload: { pageId: string; newPageName: string; newPagePath: string }) => ({
    payload,
  }),
);

export const updateCurrentRoute = createAction(
  "UPDATE_CURRENT_ROUTE",
  (payload?: {
    routeDef: RouteDef;
    params: Record<string, unknown>;
    isNewPage: boolean;
    skipPageLoad?: boolean;
  }) => ({
    payload: payload as
      | undefined
      | {
          routeDef: RouteDef;
          params: Record<string, string>;
          isNewPage: boolean;
          skipPageLoad?: boolean;
        },
  }),
);

export const clearCurrentRoute = () => updateCurrentRoute();

export const createRoute = createAction<{
  routeDef: {
    id?: string;
    path: string;
    pageId: string;
    widgetId?: string;
    testParams?: Record<string, string>;
  };
  addSlideoutOnClose: boolean;
}>("CREATE_ROUTE");

export const updateDataUrl = createAction("UPDATE_DATA_URL");

export const initCanvasLayout = createAction<{
  pageWidgetId: string;
  widgets: WidgetMap;
  stateVarMap?: Record<string, AppStateVar>;
  timerMap?: Record<string, AppTimer>;
  eventMap?: Record<string, EventDefinitionLoose>;
  currentLayoutId: string;
  currentPageId: string;
  currentApplicationId: string;
  savePage?: boolean;
}>("INIT_CANVAS_LAYOUT");

export const deleteCurrentPage = createAction("DELETE_CURRENT_PAGE");

export const deletePageInit = createAction(
  "DELETE_PAGE_INIT",
  (payload: { applicationId: string; pageId: string; branch?: string }) => ({
    payload,
  }),
);

export const deletePageSuccess = createAction("DELETE_PAGE_SUCCESS");

/**
 * This action indicates that the evaluation has completed and the we are ready to save the page.
 * Note that the page saga may choose to not save the page immediately.
 * */
export const requestPageSave = createAction("REQUEST_PAGE_SAVE");

export const updateLayout = createAction(
  "UPDATE_LAYOUT",
  (
    widgets: CanvasWidgetsReduxState,
    savePage = true,
    clearReplayStack = false,
  ) => {
    return {
      payload: { widgets, savePage, clearReplayStack },
    };
  },
);

export const updatePartialLayout = createAction(
  "UPDATE_PARTIAL_LAYOUT",
  (
    widgets: CanvasWidgetsReduxState,
    savePage = true,
    clearReplayStack = false,
  ) => {
    return {
      payload: { widgets, savePage, clearReplayStack },
    };
  },
);

export const updateLayoutPosition = createAction(
  "UPDATE_LAYOUT_POSITIONS",
  (widgets: UpdateWidgetPositionsPayload["widgets"]) => {
    return {
      payload: { widgets } satisfies UpdateWidgetPositionsPayload,
    };
  },
);

export const updateCachedData = createAction(
  "UPDATE_CACHED_DATA",
  (cachedData: Partial<CachedData6>) => {
    return {
      payload: { cachedData },
    };
  },
);

export const savePageSuccess = createAction("SAVE_PAGE_SUCCESS");

export type GridPosition = {
  top: Dimension<"gridUnit">;
  left: Dimension<"gridUnit">;
};

export type Size = {
  width: Dimension<WidgetWidthModes>;
  height: Dimension<WidgetHeightModes>;
};

export type WidgetAddChild = {
  /** parent widget id */
  widgetId: string;
  widgetName?: string;
  type: WidgetType;
  position: GridPosition;
  size: Size;
  /** should be generated somehow, likely generateReactKey() */
  newWidgetId: string;
  tabId?: string;
  /** Default is to add as the last child, but can also be inserted in relative order */
  newChildIndex?: number;
  props?: Record<string, any>;
  childProps?: Record<string, any>;
  stackAdjustments?: StackAdjustmentsInfo;
  skipCreateHooks?: boolean;
  skipChildGeneration?: boolean;
  skipSave?: boolean;
};

export type WidgetAddSectionColumn = {
  sectionWidgetId: string;
  columnProperties?: Record<string, any>;
};

export type WidgetUpdateChildren = {
  widgetId: string;
  newChildren: string[];
};

export type WidgetAddSection = {
  sectionWidgetId: string;
  placement?: "above" | "below";
};

export type WidgetResizeSection = {
  sectionWidgetId: string;
  constraintType: WidgetHeightConstraintType;
  newHeight: Dimension<"gridUnit" | "px">;
};

export const addModelOrSlideoutPayload = (
  widgetType: WidgetTypes.MODAL_WIDGET | WidgetTypes.SLIDEOUT_WIDGET,
  widgetId: string,
) => {
  return {
    type: widgetType,
    newWidgetId: widgetId,
    size: {
      width: Dimension.gridUnit(0),
      height: Dimension.gridUnit(0),
    },
    position: {
      left: Dimension.gridUnit(0),
      top: Dimension.gridUnit(0),
    },
  } satisfies Omit<WidgetAddChild, "widgetId">;
};

export type WidgetMove = {
  widgetId: string;
  position: GridPosition;
  size?: Size; // The size CAN change, but it is not mandatory

  parentId: string;
  /*
    If newParentId is different from what we have in redux store,
    then we have to delete this,
    as it has been dropped in another container somewhere.
  */
  newParentId: string;
  newChildIndex?: number; // this only applies for moves within non-fixed layouts
  stackAdjustments?: StackAdjustmentsInfo;

  // Used during a grouping operation for grids so we handle positioning correctly in new grid
  // to avoid visual shifting & widget overlapping
  offset?: GridPosition;
};

export type WidgetDelete = {
  widgetIds?: string[];
  haveDifferentParents?: boolean;
  disallowUndo?: boolean;
  isShortcut?: boolean;
};

export type WidgetResize = {
  widgetId: string;
  position?: GridPosition;
  size: Partial<Size>;
};

export type WidgetReparent = {
  widgetId: string;
  newParentId: string;
};

export type WidgetAutoWidthUpdate = {
  widgetId: string;
  widthInPx: number;
};

export type WidgetAddChildIfNotExists = {
  widgetId: string;
  children: Array<{
    type: WidgetType;
    widgetId: string;
    parentId: string;
    position: GridPosition;
    size: Size;
    isLoading: boolean; // TODO(Layout): Remove this
    tabId?: string; // For tabs
    tabName?: string; // For tabs
    padding?: Padding;
    detachFromLayout?: boolean; // TODO(Layout): Can we remove this?
    parentRowSpace: number; // TODO(Layout): Can we remove this?
    parentColumnSpace: number; // TODO(Layout): Can we remove this?
  }>;
};

export type WidgetOperationPayloads = {
  WIDGET_CREATE: WidgetAddChild;
  WIDGET_ADD_CHILD: WidgetAddChild;
  WIDGETS_MOVE: WidgetMove;
  WIDGET_RESIZE: WidgetResize;
  WIDGET_REPARENT: WidgetReparent;
  WIDGET_DELETE: WidgetDelete;
  WIDGET_ADD_CHILD_IF_NOT_EXISTS: WidgetAddChildIfNotExists;
  UPDATE_WIDGET_PROPERTIES: any; // TODO: Add type
};

export const updateWidget = <
  Op extends WidgetOperation & keyof WidgetOperationPayloads,
>(
  operation: Op,
  widgetId: string,
  payload: Omit<WidgetOperationPayloads[Op], "widgetId">,
): ReduxAction<WidgetOperationPayloads[Op]> => {
  return {
    type: operation,
    payload: { widgetId, ...payload } as WidgetOperationPayloads[Op],
  };
};

export const setUrlData = createAction(
  "SET_URL_DATA",
  (payload: UrlDataState) => ({
    payload,
  }),
);

export const setAppMode = createAction("SET_APP_MODE", (payload: APP_MODE) => ({
  payload,
}));

export const updateAppStore = (
  payload: Record<string, unknown>,
): ReduxAction<Record<string, unknown>> => {
  return {
    type: ReduxActionTypes.UPDATE_APP_STORE,
    payload,
  };
};

export const updateResponsiveCanvasMetadata = createAction(
  "UPDATE_RESPONSIVE_CANVAS_METADATA",
  (payload: { unscaledWidth?: number; canvasScaleFactor?: number }) => ({
    payload,
  }),
);

export const updateScrollbarWidth = createAction(
  "UPDATE_APP_SCROLLBAR_WIDTH",
  (newWidth: number) => ({
    payload: {
      scrollbarWidth: newWidth,
    },
  }),
);

export const undoAction = createAction(
  "UNDO_REDO_OPERATION",
  (payload?: { times: number }) => ({
    payload: {
      operation: ReplayReduxActionTypes.UNDO,
      times: payload?.times ?? 1,
    },
  }),
);

export const redoAction = createAction("UNDO_REDO_OPERATION", () => ({
  payload: {
    operation: ReplayReduxActionTypes.REDO,
  },
}));
