import {
  ApplicationScope,
  containsBindingsAnywhere,
} from "@superblocksteam/shared";
import * as diff from "diff";
import parser from "fast-xml-parser";
import HJSON from "hjson";
import lodash, {
  get,
  find,
  isString,
  VERSION as lodashVersion,
  isArray,
  isObject,
  remove,
  unset,
  set,
  hasIn,
} from "lodash";
import moment from "moment-timezone";
import {
  adjustHue,
  complement,
  darken,
  desaturate,
  getContrast,
  getLuminance,
  grayscale,
  hsl,
  hsla,
  hslToColorString,
  invert,
  lighten,
  meetsContrastGuidelines,
  mix,
  opacify,
  parseToHsl,
  parseToRgb,
  readableColor,
  rgb,
  rgba,
  rgbToColorString,
  saturate,
  setHue,
  setLightness,
  setSaturation,
  shade,
  tint,
  toColorString,
  transparentize,
} from "polished";
import { PredefinedFunctionDescription } from "legacy/constants/TriggerConstants";
import { getAllPathsFromPropertyConfig } from "legacy/entities/Widget/utils";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { MetaState } from "legacy/reducers/entityReducers/metaReducer";
import { type ReferenceMetaPayload } from "legacy/workers/utils/ReferenceManager";
import { FlagType } from "store/slices/featureFlags/models/Flags";
import { fastClone } from "utils/clone";
import { stringifySplitPaths } from "utils/dottedPaths";
import {
  ActionTriggerMap,
  DependencyMap,
  DynamicPath,
  EntityWithBindings,
} from "./DynamicBindingTypes";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import type {
  DataTree,
  DataTreeMetaPaths,
} from "legacy/entities/DataTree/dataTreeFactory";
import type { WidgetTypeConfigMap, WidgetProps } from "legacy/widgets";
export enum EvalErrorTypes {
  DEPENDENCY_ERROR = "DEPENDENCY_ERROR",
  EVAL_PROPERTY_ERROR = "EVAL_PROPERTY_ERROR",
  EVAL_TREE_ERROR = "EVAL_TREE_ERROR",
  UNESCAPE_STRING_ERROR = "UNESCAPE_STRING_ERROR",
  EVAL_ERROR = "EVAL_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  BAD_UNEVAL_TREE_ERROR = "BAD_UNEVAL_TREE_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
}

export type EvalError<Context = Record<string, any>> = {
  type: EvalErrorTypes;
  message: string;
  context?: Context;
};

// TODO: make the narrow based on type property
export type DependencyEvalError = EvalError<{
  nodes: string[];
  propertyPath: string;
}> & {
  type: EvalErrorTypes.DEPENDENCY_ERROR;
};

export enum EVAL_WORKER_ACTIONS {
  EVAL_TREE_REC_EVAL = "EVAL_TREE_REC_EVAL",

  EVAL_ACTION_BINDINGS_REC_EVAL = "EVAL_ACTION_BINDINGS_REC_EVAL",

  EVAL_TRIGGER_REC_EVAL = "EVAL_TRIGGER_REC_EVAL",

  CLEAR_PROPERTY_CACHE_OF_WIDGET = "CLEAR_PROPERTY_CACHE_OF_WIDGET",
  CLEAR_CACHE = "CLEAR_CACHE",

  UNDO = "undo",
  REDO = "redo",

  GET_UNDO_STACK_LENGTH = "GET_UNDO_STACK_LENGTH",
}

type Handler<Input, Output> = (
  input: Input,
  actions: {
    onAfter: (f: () => void) => void;
  },
) => Output;

export interface ACTION_HANDLERS {
  EVAL_TREE_REC_EVAL: Handler<EvalTreeRequest, EvalTreeResponse>;

  EVAL_ACTION_BINDINGS_REC_EVAL: Handler<
    EvalActionBindingsRequest,
    EvalActionBindingsResponse
  >;

  EVAL_TRIGGER_REC_EVAL: Handler<EvalTriggerRequest, EvalTriggerResponse>;

  CLEAR_PROPERTY_CACHE_OF_WIDGET: Handler<{ widgetName: string }, void>;
  CLEAR_CACHE: Handler<void, void>;

  [EVAL_WORKER_ACTIONS.UNDO]: Handler<{ times: number }, any>;
  [EVAL_WORKER_ACTIONS.REDO]: Handler<{ times: number }, any>;

  [EVAL_WORKER_ACTIONS.GET_UNDO_STACK_LENGTH]: Handler<void, any>;
}

export interface ReplayStateOptions {
  shouldReplay: boolean;
  clearReplayStack: boolean;
}

export interface ReplayState extends ReplayStateOptions {
  widgets: CanvasWidgetsReduxState;
  widgetMeta: MetaState;
}

interface EvalOptions {
  staticAnalysis: boolean;
}

export interface EvalTreeRequest {
  unevalTree: DataTree;
  metaPaths: DataTreeMetaPaths;
  widgetTypeConfigMap: WidgetTypeConfigMap | undefined;
  replayState: ReplayState;
  options: EvalOptions;
}

export interface EvalTreeResponse {
  dataTree: DataTree;
  triggerMap: ActionTriggerMap;
  dependencies: DependencyMap;
  entityDependencyMap: DependencyMap;
  entityToApiDepMap: DependencyMap;
  referenceMeta: ReferenceMetaPayload;
  logs: any[];
  errors: EvalError[];
}

export interface EvalActionBindingsRequest {
  bindings: string[];
  scope: ApplicationScope;
  executionParams: Record<string, any> | string;
  additionalNamedArguments?: Record<string, unknown>;
}
export interface EvalActionBindingsResponse {
  values: any[];
  errors: EvalError[];
  logs: any[];
}

export interface EvalTriggerRequest {
  scope: ApplicationScope;
  dynamicTrigger: string;
  additionalNamedArguments?: Record<string, unknown>;
}

export interface EvalTriggerResponse {
  triggers: PredefinedFunctionDescription[] | undefined;
  errors: EvalError[];
  logs: any[];
}

type ExtraLibrary = {
  version: string;
  docsURL: string;
  displayName: string;
  accessor: string;
  lib: any;
};

const extraLibraries: ExtraLibrary[] = [
  {
    accessor: "_",
    lib: lodash,
    version: lodashVersion,
    docsURL: `https://lodash.com/docs/${lodashVersion}`,
    displayName: "lodash",
  },
  {
    accessor: "moment",
    lib: moment,
    version: moment.version,
    docsURL: `https://momentjs.com/docs/`,
    displayName: "moment",
  },
  {
    accessor: "diff",
    lib: diff,
    version: "5.1.0",
    docsURL: `https://github.com/kpdecker/jsdiff`,
    displayName: "diff",
  },
  {
    accessor: "xmlParser",
    lib: parser,
    version: "3.17.5",
    docsURL: "https://github.com/NaturalIntelligence/fast-xml-parser",
    displayName: "xmlParser",
  },
  {
    accessor: "polished",
    lib: {
      adjustHue,
      complement,
      darken,
      desaturate,
      getContrast,
      getLuminance,
      grayscale,
      hsl,
      hsla,
      hslToColorString,
      invert,
      lighten,
      meetsContrastGuidelines,
      mix,
      opacify,
      parseToHsl,
      parseToRgb,
      readableColor,
      rgb,
      rgba,
      rgbToColorString,
      saturate,
      setHue,
      setLightness,
      setSaturation,
      shade,
      tint,
      toColorString,
      transparentize,
    },
    version: "4.3.1",
    docsURL: "https://github.com/styled-components/polished",
    displayName: "polished",
  },
  {
    accessor: "HJSON",
    lib: HJSON,
    version: "3.2.2",
    docsURL: "https://github.com/hjson/hjson-js",
    displayName: "HJSON",
  },
];

// export the above libs as a map
export const extraLibrariesMap: Map<string, unknown> = new Map(
  extraLibraries.map((library) => [library.accessor, library.lib]),
);

/**
 * Note: This function will remove any child bindings if a parent binding is present.
 *
 * In newer apps we'll only have the deepest child bindings paths instead of parents (see changes to `getDynamicBindingPathListUpdate`).
 */
export const getEntityDynamicBindingPathList = (
  entity: EntityWithBindings,
): DynamicPath[] => {
  if (
    entity &&
    entity.dynamicBindingPathList &&
    Array.isArray(entity.dynamicBindingPathList)
  ) {
    // We need to check if any of the dynamic bindings are children of other bindings
    // If they are, we need to remove the children, as the parent will be evaluated
    // use the isChildPropertyPath function to check if a path is a child of another
    const dynamicBindings = entity.dynamicBindingPathList;
    return dynamicBindings.filter((binding) => {
      return !dynamicBindings.some((parentBinding) => {
        if (parentBinding.key === binding.key) return false;
        return isChildPropertyPath(parentBinding.key, binding.key);
      });
    });
  }
  return [];
};

export const isPathADynamicBinding = (
  entity: EntityWithBindings,
  path: string,
): boolean => {
  if (
    entity &&
    entity.dynamicBindingPathList &&
    Array.isArray(entity.dynamicBindingPathList)
  ) {
    const normalize = (key: string) => key.replace(/\["(\d+)"\]/g, "[$1]");
    const normalizedPath = path.replace(/\["(\d+)"\]/g, "[$1]");

    return entity.dynamicBindingPathList.some(
      ({ key }) => key === path || normalize(key) === normalizedPath,
    );
  }
  return false;
};

const getWidgetDynamicTriggerPathList = (
  entity: EntityWithBindings,
): DynamicPath[] => {
  if (
    entity &&
    entity.dynamicTriggerPathList &&
    Array.isArray(entity.dynamicTriggerPathList)
  ) {
    return [...entity.dynamicTriggerPathList];
  }
  return [];
};

// Examples: onClick, primaryColumns.myButton.onClick
export const isPathADynamicTrigger = (
  item: EntityWithBindings,
  path: string,
  strictMatch = false,
): boolean => {
  if (
    item &&
    item.dynamicTriggerPathList &&
    Array.isArray(item.dynamicTriggerPathList)
  ) {
    return item.dynamicTriggerPathList.some(
      ({ key }) =>
        path === key || (!strictMatch ? path.startsWith(key + "[") : false),
    );
  }

  return false;
};

// Examples: onClick but not primaryColumns.myButton.onClick
const isPathATriggerPathFromKnownPaths = (
  entity: EntityWithBindings,
  entityConfig: readonly PropertyPaneConfig[],
  path: string,
): boolean => {
  const { triggerPaths } = getAllPathsFromPropertyConfig(
    entity,
    entityConfig,
    {},
  );
  return Object.keys(triggerPaths).some(
    (key) => path === key || path.startsWith(key + "["),
  );
};

export const getWidgetDynamicPropertyPathList = (
  widget: EntityWithBindings,
): DynamicPath[] => {
  if (
    widget &&
    widget.dynamicPropertyPathList &&
    Array.isArray(widget.dynamicPropertyPathList)
  ) {
    return [...widget.dynamicPropertyPathList];
  }
  return [];
};

export const isPathADynamicProperty = (
  widget: EntityWithBindings,
  path: string,
): boolean => {
  if (
    widget &&
    widget.dynamicPropertyPathList &&
    Array.isArray(widget.dynamicPropertyPathList)
  ) {
    return find(widget.dynamicPropertyPathList, { key: path }) !== undefined;
  }
  return false;
};

const unsafeFunctionForEval = [
  "setTimeout",
  "setInterval",
  "requestAnimationFrame",
  "Promise",
  "fetch",
  "close",
  "addEventListener",
  "removeEventListener",
  "cancelAnimationFrame",
  "clearTimeout",
  "clearInterval",
];

export const unsafeGlobalsForEval = new Set(unsafeFunctionForEval);

export const globalsRelyingOnWorkerGlobalScope = new Set([
  "atob",
  "btoa",
  "createImageBitmap",
  "queueMicrotask",
  "reportError",
  "structuredClone",
]);

export const isChildPropertyPath = (
  parentPropertyPath: string,
  childPropertyPath: string,
): boolean =>
  parentPropertyPath === childPropertyPath ||
  childPropertyPath.startsWith(`${parentPropertyPath}.`) ||
  childPropertyPath.startsWith(`${parentPropertyPath}[`);

enum DynamicPathUpdateEffectEnum {
  ADD = "ADD",
  REMOVE = "REMOVE",
  NOOP = "NOOP",
}

type DynamicPathUpdate = {
  propertyPath: string;
  effect: DynamicPathUpdateEffectEnum;
};

function getDynamicTriggerPathListUpdate(
  widget: EntityWithBindings,
  propertyPath: string,
  propertyValue: string,
  isDynamicTrigger?: boolean,
): DynamicPathUpdate {
  if (
    (propertyValue && !isPathADynamicTrigger(widget, propertyPath)) ||
    isDynamicTrigger
  ) {
    return {
      propertyPath,
      effect: DynamicPathUpdateEffectEnum.ADD,
    };
  } else if (!propertyValue && !isPathADynamicTrigger(widget, propertyPath)) {
    return {
      propertyPath,
      effect: DynamicPathUpdateEffectEnum.REMOVE,
    };
  }
  return {
    propertyPath,
    effect: DynamicPathUpdateEffectEnum.NOOP,
  };
}

function getDynamicBindingPathListUpdate(
  widget: EntityWithBindings,
  propertyPath: string,
  propertyValue: any,
): DynamicPathUpdate {
  let stringProp = propertyValue;
  if (isObject(propertyValue)) {
    // Stringify this because composite controls may have bindings in the sub controls
    stringProp = JSON.stringify(propertyValue);
  }

  //TODO(abhinav): This is not appropriate from the platform's archtecture's point of view.
  // Figure out a holistic solutions where we donot have to stringify above.
  if (propertyPath === "primaryColumns" || propertyPath === "derivedColumns") {
    return {
      propertyPath,
      effect: DynamicPathUpdateEffectEnum.NOOP,
    };
  }

  const isDynamic = containsBindingsAnywhere(stringProp);
  if (!isDynamic && isPathADynamicBinding(widget, propertyPath)) {
    return {
      propertyPath,
      effect: DynamicPathUpdateEffectEnum.REMOVE,
    };
  } else if (isDynamic && !isPathADynamicBinding(widget, propertyPath)) {
    return {
      propertyPath,
      effect: DynamicPathUpdateEffectEnum.ADD,
    };
  }
  return {
    propertyPath,
    effect: DynamicPathUpdateEffectEnum.NOOP,
  };
}

/**
 * Gets all dynamic binding path updates effects, preferring the deepest paths over parent paths.
 * For example, if an object has: { parent: { child1: "{{data}}", child2: "{{data2}}" } }
 * It will return paths for "parent.child1" and "parent.child2" rather than just "parent".
 *
 * Time ago, we used to rely on JSON.stringify to look for bindings in objects.
 * But this prevented us from having double quotes "" inside those bindings
 *
 * Note: this function won't create a delete effect for stale bindings if the property still exists in the widget.
 * A further check must be done after the widget has been updated.
 */
export function getDynamicBindingPathListUpdateV2(
  widget: EntityWithBindings,
  propertyPath: string,
  propertyValue: any,
): DynamicPathUpdate[] {
  if (propertyPath === "primaryColumns" || propertyPath === "derivedColumns") {
    // TODO(abhinav): This is not appropriate from the platform's architecture point of view.
    return [];
  }

  if (isObject(propertyValue)) {
    const updates = Object.entries(propertyValue).flatMap(([key, value]) => {
      const path = Array.isArray(propertyValue)
        ? stringifySplitPaths([propertyPath, `[${key}]`]) // key is the string of a number because of `Object.entries(array)`
        : stringifySplitPaths([propertyPath, key]);

      return getDynamicBindingPathListUpdateV2(widget, path, value);
    });

    // Check if any child paths are being added, if so, remove the parent path
    const hasChildAddEffect = updates.some(
      (update) => update.effect === DynamicPathUpdateEffectEnum.ADD,
    );

    if (hasChildAddEffect && isPathADynamicBinding(widget, propertyPath)) {
      updates.push({
        propertyPath,
        effect: DynamicPathUpdateEffectEnum.REMOVE,
      });
    }
    return updates;
  }

  const isDynamic = containsBindingsAnywhere(propertyValue);
  if (!isDynamic && isPathADynamicBinding(widget, propertyPath)) {
    return [
      {
        propertyPath,
        effect: DynamicPathUpdateEffectEnum.REMOVE,
      },
    ];
  } else if (isDynamic && !isPathADynamicBinding(widget, propertyPath)) {
    return [
      {
        propertyPath,
        effect: DynamicPathUpdateEffectEnum.ADD,
      },
    ];
  }
  return [];
}
function applyDynamicPathUpdates(
  currentList: DynamicPath[],
  update: DynamicPathUpdate,
): DynamicPath[] {
  if (update.effect === DynamicPathUpdateEffectEnum.ADD) {
    currentList.push({
      key: update.propertyPath,
    });
  } else if (update.effect === DynamicPathUpdateEffectEnum.REMOVE) {
    remove(currentList, { key: update.propertyPath });
  }
  return currentList;
}

/**
 * Creates a new array with filtered out bindings from an entity's dynamicBindingPathList that no longer exist in the entity.
 *
 * @param entity - The entity containing dynamic bindings
 * @returns An array of DynamicPath objects, excluding any dynamic binding paths that references a property that no longer exists.
 */
export function getDynamicBindingPathListWithExistingReferences(
  entity: EntityWithBindings,
): DynamicPath[] {
  if (
    entity &&
    entity.dynamicBindingPathList &&
    Array.isArray(entity.dynamicBindingPathList)
  ) {
    return entity.dynamicBindingPathList.filter((dynamicBinding) => {
      return hasIn(entity, dynamicBinding.key);
    });
  }

  return [];
}

/**
 * It takes a widget, a list of properties, and a list of updates, and returns a list of updates that
 * includes the original updates, plus any updates to the dynamicTriggerPathList and
 * dynamicBindingPathList
 *
 * Note: this function won't remove stale bindings if a property path no longer exists due to the `updates`.
 * A separate cleanup is needed after the widget is updated to remove bindings of those deleted properties.

 * @param {EntityWithBindings} entity - The entity that is being updated.
 * @param entityConfig - The property pane config for the entity.
 * @param updates - Record<string, unknown>
 */
export function mergeUpdatesWithBindingsOrTriggers(
  entity: EntityWithBindings,
  entityConfig: readonly PropertyPaneConfig[],
  updates: Record<string, unknown>,
  deepBindingsFeatureFlagIsEnabled: FlagType | undefined, // TODO: remove after FF is on
): Record<string, unknown> {
  const propertyUpdates: Record<string, unknown> = {};
  const currentDynamicTriggerPathList: DynamicPath[] =
    getWidgetDynamicTriggerPathList(entity);
  const currentDynamicBindingPathList: DynamicPath[] =
    getEntityDynamicBindingPathList(entity);

  const updatedWidget = fastClone(entity);
  if (updates.dynamicTriggerPathList) {
    updatedWidget.dynamicTriggerPathList =
      updates.dynamicTriggerPathList as DynamicPath[];
  }

  const dynamicTriggerPathListUpdates: DynamicPathUpdate[] = [];
  const dynamicBindingPathListUpdates: DynamicPathUpdate[] = [];
  Object.entries(updates).forEach(([propertyPath, propertyValue]) => {
    // Set the actual property update
    propertyUpdates[propertyPath] = propertyValue;

    // Check if the path is a of a dynamic trigger property. Some properties are known statically, but
    // we also need to check the propertyConfig for dynamic properties like ones used by the table
    // This includes newly added triggers such as JS steps
    const isTriggerProperty =
      isPathATriggerPathFromKnownPaths(
        updatedWidget,
        entityConfig,
        propertyPath,
      ) || isPathADynamicTrigger(updatedWidget, propertyPath);

    // If it is a trigger property, it will go in a different list than the general
    // dynamicBindingPathList.
    if (isTriggerProperty) {
      if (isString(propertyValue)) {
        dynamicTriggerPathListUpdates.push(
          getDynamicTriggerPathListUpdate(
            updatedWidget,
            propertyPath,
            propertyValue,
          ),
        );
      } else if (isArray(propertyValue)) {
        propertyValue.forEach((propValue) => {
          dynamicTriggerPathListUpdates.push(
            getDynamicTriggerPathListUpdate(entity, propertyPath, propValue),
          );
        });
      }
    } else {
      if (deepBindingsFeatureFlagIsEnabled) {
        dynamicBindingPathListUpdates.push(
          ...getDynamicBindingPathListUpdateV2(
            entity,
            propertyPath,
            propertyValue,
          ),
        );
      } else {
        dynamicBindingPathListUpdates.push(
          getDynamicBindingPathListUpdate(entity, propertyPath, propertyValue),
        );
      }
    }
  });

  propertyUpdates.dynamicTriggerPathList = dynamicTriggerPathListUpdates.reduce(
    applyDynamicPathUpdates,
    currentDynamicTriggerPathList,
  );
  propertyUpdates.dynamicBindingPathList = dynamicBindingPathListUpdates.reduce(
    applyDynamicPathUpdates,
    currentDynamicBindingPathList,
  );
  return propertyUpdates;
}

export const unsetPropertyPath = (
  obj: Record<string, unknown>,
  path: string,
) => {
  const regex = /(.*)\[\d+\]$/;
  if (regex.test(path)) {
    const matches = path.match(regex);
    if (
      matches &&
      Array.isArray(matches) &&
      matches[1] &&
      matches[1].length > 0
    ) {
      unset(obj, path);
      const arr = get(obj, matches[1]);
      if (arr && Array.isArray(arr)) {
        set(obj, matches[1], arr.filter(Boolean));
      }
    }
  } else {
    unset(obj, path);
  }
  return obj;
};

export function deleteWithBindingsOrTriggers(
  widget: Readonly<WidgetProps>,
  propertyPaths: string[],
): WidgetProps {
  let updated = fastClone(widget) as WidgetProps;
  try {
    let dynamicTriggerPathList: DynamicPath[] =
      getWidgetDynamicTriggerPathList(widget);
    let dynamicBindingPathList: DynamicPath[] =
      getEntityDynamicBindingPathList(widget);

    propertyPaths.forEach((propertyPath) => {
      dynamicTriggerPathList = dynamicTriggerPathList.filter((dynamicPath) => {
        return !isChildPropertyPath(propertyPath, dynamicPath.key);
      });

      dynamicBindingPathList = dynamicBindingPathList.filter((dynamicPath) => {
        return !isChildPropertyPath(propertyPath, dynamicPath.key);
      });
    });

    updated.dynamicBindingPathList = dynamicBindingPathList;
    updated.dynamicTriggerPathList = dynamicTriggerPathList;

    // Cloning because we probably froze the properties earlier
    propertyPaths.forEach((propertyPath) => {
      updated = unsetPropertyPath(
        updated as Record<string, any>,
        propertyPath,
      ) as Record<string, any> as WidgetProps;
    });

    return updated;
  } catch (e) {
    return updated;
  }
}
